import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import Notification from "../components/Notification";
import * as QueryString from "query-string";
import { useLocation } from "react-router-dom";
import LandinPage from "./landingpage";
import {CONFIG} from "../constants/conifiguration";
import client1Icon from "../assets/icons/A_logo.png";
import client2Icon from "../assets/icons/Client-2-logo.jpg";
import client3Icon from "../assets/icons/client-3-logo.svg"

const App = () => {
  const location = useLocation();
  const [loginBool, setLoginBool] = useState(false);
  const { user_details } = QueryString.parse(location.search);
  
    useEffect(() => {
    if (user_details) {
      let obj = JSON.parse(user_details);
      localStorage.setItem(
        "user_details",
        JSON.stringify({
          accessToken: obj.accessToken,
          username: obj.username,
          appId: obj.appId,
        })
      );
    } else {
      setLoginBool(true);
    }
  }, []);
  const showLoading = useSelector((state) => state.commonReducer.showLoading);
  let imgPath = document.getElementById("favIcon")
  let appTitle = document.getElementById("app_title")

  if(CONFIG.product === "CLIENT1_R"){
    imgPath.href=client1Icon
    appTitle.innerHTML="User Management"
  }
  else if (CONFIG.product === "CLIENT2_B") {
    imgPath.href=client2Icon
    appTitle.innerHTML="User Management"
  }
  else if (CONFIG.product === "CLIENT3_A") {
    imgPath.href=client3Icon
    appTitle.innerHTML="User Management"
  }

  return (
    <>
      <Loading show={showLoading} />
      <Notification />
      <LandinPage loginBool={loginBool} user_details={user_details} location={location}/>
    </>
  );
};

export default App;
