import React, { lazy, useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { showLoader, hideLoader,setGroups } from "../../duck/actions/commonActions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAppPermission } from "../../api/login";
import mapJson from "../../constants/commonConstants/routeDetails.json";
import SuspenseWrapper from "../../components/SuspenseWrapper";
import { APP_AUTH } from "../../constants/apiBaseUrl";

const Dashboard = lazy(() => import("../dashboard"));
const Account = lazy(() => import("../user"));

const LandinPage = ({ loginBool, user_details ,location }) => {
  let [page, setpage] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  
  const getUserRoleConfig = async () => {
    try {
      if (user_details) {
        dispatch(showLoader());
        let user = JSON.parse(user_details);
        let auth = user.accessToken;

        let appId = user.appId;
        const res = await getAppPermission(auth, { appId });
        let group=res?.name;
        dispatch(setGroups(group));
        let routes = []
        res.modules.map((record)=> record?.permission?.read && routes.push(record?.name))
        localStorage.setItem("routes",[...routes])
        localStorage.setItem("landingPage",res?.landingPage)
        setpage(res?.landingPage ?? "");
      } else if (
        JSON.parse(localStorage.getItem("user_details"))["accessToken"]
      ) {
        dispatch(showLoader());
        let auth = JSON.parse(localStorage.getItem("user_details"))[
          "accessToken"
        ];
        let appId = JSON.parse(localStorage.getItem("user_details"))["appId"];
        const res = await getAppPermission(auth, { appId });
        let group=res?.name;
        dispatch(setGroups(group))
        let routes = []
        res.modules.map((record)=> record?.permission?.read && routes.push(record?.name))
        localStorage.setItem("routes",[...routes])
        localStorage.setItem("landingPage",res?.landingPage)
        setpage(res?.landingPage ?? "");

      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getUserRoleConfig();
    let pathName = mapJson[page];

    history.replace({
      pathname: pathName,
      search: "",
      state: {},
    });
  }, [page]);
  const match = useRouteMatch();
  const showLoading = useSelector((state) => state.commonReducer.showLoading);
  return (
    <div style={{ opacity: showLoading ? 0.5 : 1 }}>
      {page && (
        <SuspenseWrapper>
          <Switch>
            <Route path={`${match.url}`} key="dashboard">
              <Dashboard location={location}/>
            </Route>
            <Route path={`${match.url}user`} key="user">
              <Account />
            </Route>
            {loginBool && (
              <Route
                exact
                path="/"
                key="login"
                component={() => {
                  window.location.href = APP_AUTH;
                  return null;
                }}
              />
            )}
          </Switch>
        </SuspenseWrapper>
      )}
    </div>
  );
};

export default LandinPage;
